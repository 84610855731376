import { format, parse } from "date-fns"
import { ParsedUrlQuery } from "querystring"

import { BrandsEnum, IFlattenURLQuery, PageSlug } from "../types"
import { getRuntimeConfig } from "./runtimeConfig"
import { ReadonlyURLSearchParams } from "next/navigation"

export function toPriceString(
  price: number,
  options?: Intl.NumberFormatOptions,
  currency?: string
): string {
  if (price === undefined || price === null) return ""
  if (typeof window === "undefined" || !window.Intl)
    return `${price.toFixed(2).replace(".", ",")}${String.fromCharCode(160)}€`

  return window.Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: currency || "EUR",
    ...options,
  }).format(price)
}

export function formatLangString(language: string): string {
  if (language.indexOf("-") === -1) return language

  const [langCode, countryCode] = language.split("-")

  return `${langCode.toLowerCase()}_${countryCode.toUpperCase()}`
}

export const isStrEqualToTrue = (value: string | null | undefined): boolean => {
  return Boolean(value && value.toLowerCase() === "true")
}

export function formatNumber(num: number): string {
  // return new Intl.NumberFormat("de-DE").format(num)
  const parts = num.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")

  return parts.join(",")
}

export function formatDate(
  dateStr: string | number,
  currentFormat: string,
  desiredFormat: string
): string {
  const parsedDate =
    typeof dateStr === "string" ? parse(dateStr, currentFormat, 0) : dateStr
  return format(parsedDate, desiredFormat)
}

/**
 * finds an intersection of 2 arrays
 */
export function intersection<T>(a1: T[], a2: T[]): T[] {
  return a1.filter((item) => a2.includes(item))
}

export function isObjectEmpty(obj: any): boolean {
  if (!obj) return true

  // according to GDD for .. in is fastest method
  // eslint-disable-next-line no-restricted-syntax
  for (const x in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, x)) return false
  }

  return true
}

export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item)
}

export function mergeDeepObjects<T>(target: any, source: any): T {
  const output = { ...target }
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else
          Object.assign(output, {
            [key]: mergeDeepObjects<T>(target[key], source[key]),
          })
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }

  return output
}

/**
 *  Typeguard. Checks if provided value not null or not undefined.
 */
export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) return false

  return true
}

export function createMarkup(html: string) {
  return { __html: html }
}

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null)
    throw new Error(`Expected 'val' to be defined, but recieved "${val}"`)
}

export const buildPageEventNameFromPathname = (pathname: string) => {
  const { hash } = window.location
  return pathname.split("/").slice(-1)[0] + hash
}

export function removeObjectFields<T = Record<string, any>>(
  obj: T,
  ...fields: Array<keyof T>
): T {
  const newObj = { ...obj }

  fields.forEach((field) => {
    delete newObj[field]
  })

  return newObj
}

export function removeSearchParamsEntries(
  searchParams: ReadonlyURLSearchParams,
  keys: string[]
): URLSearchParams {
  const editableParams = new URLSearchParams(searchParams)
  keys.forEach((key) => {
    editableParams.delete(key)
  })
  return editableParams
}

export function updateSearchParamsFromEntries(
  searchParams: URLSearchParams = new URLSearchParams(),
  entry: Record<string, any>
): URLSearchParams {
  Object.entries(entry).forEach(([key, value]) => {
    if (typeof value !== "undefined") {
      searchParams.set(key, value === null ? "" : value.toString())
    }
  })
  return searchParams
}

export const isServer = () => typeof window === "undefined"

/**
 * Transforms array-values into strings, by picking value of 0 item
 *
 * @returns flattened object. { a: ["1", "2"] } => { a: "1" }
 */
export function flattenQueryParams(query: ParsedUrlQuery) {
  return Object.entries(query).reduce<IFlattenURLQuery>((acc, [key, val]) => {
    acc[key] = Array.isArray(val) ? val[0] : val

    return acc
  }, {})
}
/*
 * Made from searchParams interface object, if same key appears several times, counts only first
 */
export function flattenedURLSearchParams(params: ReadonlyURLSearchParams) {
  const flattened: IFlattenURLQuery = {}
  params.forEach((value, key) => {
    if (!flattened.hasOwnProperty(key)) {
      flattened[key] = value
    }
  })
  return flattened
}

export const isNumber = (value: unknown): boolean =>
  !!value &&
  !(typeof value === "undefined" || value === null || value === false) &&
  !Number.isNaN(Number(value))

export const makePOSTRedirect = (
  action: string,
  data?: Record<string, string>
): void => {
  const $form = document.createElement("form")
  $form.action = action
  $form.method = "POST"

  if (data) {
    Object.keys(data).forEach((k) => {
      const $input = document.createElement("input")
      $input.type = "hidden"
      $input.name = k
      $input.value = data[k]
      $form.appendChild($input)
    })
  }
  document.body.appendChild($form)
  $form.submit()
}

export const isQuesticoBrand = () => {
  const { MANDATOR_NUMBER } = getRuntimeConfig()

  return BrandsEnum.QUESTICO === MANDATOR_NUMBER
}

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const getBrandValue = (): "questico" | "viversum" => {
  return isQuesticoBrand() ? "questico" : "viversum"
}

export const getBrandName = () => capitalizeFirstLetter(getBrandValue())

export const getBrandDashboardSlug = () => {
  return PageSlug[
    `${getBrandValue().toUpperCase()}_DASHBOARD` as keyof typeof PageSlug
  ]
}

export function getPageTitleByPathname(pathname: string | null) {
  return pathname
    ? `${getBrandName()} ${pathname.split("/").join("- ")}`
    : getBrandName()
}

const brand = getBrandValue()

export function isRootDashboard(pathname: string) {
  return pathname === `/mein-${brand}`
}
