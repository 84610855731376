/* eslint-disable camelcase */
import { JwtPayload } from "jwt-decode"
import { NextPageContext } from "next"
import { ApolloClient, Context, ApolloContextValue } from "@apollo/client"
import { NormalizedCacheObject } from "@apollo/client/cache"
import { LinkProps as MaterialUILinkProps } from "@mui/material/Link"
import { Document } from "@contentful/rich-text-types"
import { UrlObject } from "url"
import { Country } from "react-phone-number-input"

import { VisitorQueryContext } from "@fingerprintjs/fingerprintjs-pro-react"
import { TUserStatus } from "./utils/tracking/types"
/**
 * We are in process on migration of all types to autogenerated.
 * As soon as we resolve all type we can fix this cycle dep.
 */
// eslint-disable-next-line import/no-cycle
import {
  IFullDom,
  ILink as _ILink,
  IMultiLevelSlug,
  IPageForMultilevelSlug as _IPageForMultilevelSlug,
} from "./graphql/contentful-schema"

/**
 * Utility types
 */

/** Pick provided properties from type T, make it required and merge to the rest properties of T with Partial */
export type SomeRequired<T, RequiredKey extends keyof T> = Required<
  Pick<T, RequiredKey>
> &
  Partial<T>

export type SomeKeysRequired<T, RequiredKeys extends keyof T> = Required<
  Pick<T, RequiredKeys>
> &
  T

export type Only<T, U> = { [P in keyof T]: T[P] } &
  Omit<{ [P in keyof U]?: never }, keyof T>

/**
 * @example
 * ```
 *  interface Base {
 *    commonProp: number; // could be optional
 *  }
 *  interface BaseWithRequiredProp1 extends Base {
 *    prop1: string;
 *  }
 *  interface BaseWithRequiredProp2 extends Base {
 *    prop2: string;
 *  }
 *  // here it MUST include either text or attachment
 *  type FinalType = Either<BaseWithRequiredProp1, BaseWithRequiredProp2>;
 * ```
 */
export type Either<T, U> = Only<T, U> | Only<U, T>

/**
 * Returns type of the key `Key` of type `T`
 */

export type UseTypeOf<T, Key extends keyof T> = T[Key]

type Identity<T> = { [P in keyof T]: T[P] }

/**
 * Utility type with a property quard. Second argument is used to check
 * what properties which we are redefining possibly get removed from base type.
 */
export type Replace<T, K extends keyof T, TReplace> = Identity<
  Pick<T, Exclude<keyof T, K>> & TReplace
>

/**
 * replaces EACH field of T with new type N
 */
export type ReplaceEachWith<T, N> = {
  [P in keyof T]: N
}

export type RequiredNotNull<T> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

export type NonNullableAll<T> = {
  [P in keyof T]: NonNullable<T[P]>
}

export type Maybe<T> = T | null

/**
 * Make one or more property non-optional
 * Example:
 * type UserWithName = WithRequired<User, "name" | "lastName">
 */

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

/**
 * recursively makes all properties of T partial
 * can be removed after migration to TS 4.1+
 *
 * But it works now
 */
export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}

export type RequiredNonNullable<T> = {
  [P in keyof T]: NonNullable<T[P]>
}

//  ######## ##    ## ##     ## ##     ##  ######
//  ##       ###   ## ##     ## ###   ### ##    ##
//  ##       ####  ## ##     ## #### #### ##
//  ######   ## ## ## ##     ## ## ### ##  ######
//  ##       ##  #### ##     ## ##     ##       ##
//  ##       ##   ### ##     ## ##     ## ##    ##
//  ######## ##    ##  #######  ##     ##  ######

export enum MouseEventButton {
  /* Main button pressed, usually the left button or the un-initialized state */
  MAIN = 0,
  /* 1: Auxiliary button pressed, usually the wheel button or the middle button (if present) */
  AUXILIARY = 1,
  /* 2: Secondary button pressed, usually the right button */
  SECONDARY = 2,
  /* 3: Fourth button, typically the Browser Back button */
  FOURTH = 3,
  /* 4: Fifth button, typically the Browser Forward button */
  FIFTH = 4,
}

export enum ListViewOptions {
  CAROUSEL = "CAROUSEL",
  // HOROSCOPE_GRID = "HOROSCOPE GRID",
  STATIC_GRID = "STATIC GRID",
  SMALL_GRID = "SMALL GRID",
  // SLIDESHOW = "SLIDESHOW",
  // VERTICAL_LIST = "VERTICAL LIST",
  // HORIZONTAL_LIST = "HORIZONTAL LIST",
}

export enum SlugType {
  PAGE = "PAGE",
  ARTICLE = "ARTICLE",
}

export enum ItemViewOptions {
  COLLAGE = "COLLAGE",
  COLLAGE_ALTERNATE = "COLLAGE_ALTERNATE",
  STACK = "STACK",
  IN_ARTICLE = "IN_ARTICLE",
  // CARD = "CARD",
  // POSTER = "POSTER",
  // OVERLAP = "OVERLAP",
}

export enum BrandsEnum {
  QUESTICO = "100",
  VIVERSUM_GERMANY = "710",
}

export enum Environment {
  PRODUCTION = "production",
  QA = "staging",
  REVIEW = "review",
  LOCAL = "local",
}

export enum ZodiacSignAliasesEnum {
  aries = "aries",
  taurus = "taurus",
  gemini = "gemini",
  cancer = "cancer",
  leo = "leo",
  virgo = "virgo",
  libra = "libra",
  scorpio = "scorpio",
  sagittarius = "sagittarius",
  capricorn = "capricorn",
  aquarius = "aquarius",
  pisces = "pisces",
}

export enum ZodiacSignEnum {
  aries = "widder",
  taurus = "stier",
  gemini = "zwillinge",
  cancer = "krebs",
  leo = "löwe",
  virgo = "jungfrau",
  libra = "waage",
  scorpio = "skorpion",
  sagittarius = "schütze",
  capricorn = "steinbock",
  aquarius = "wassermann",
  pisces = "fische",
}

export const ZodiacNumberMapping: { [k: string]: ZodiacSignEnum } = {
  1: ZodiacSignEnum.aries,
  2: ZodiacSignEnum.taurus,
  3: ZodiacSignEnum.gemini,
  4: ZodiacSignEnum.cancer,
  5: ZodiacSignEnum.leo,
  6: ZodiacSignEnum.virgo,
  7: ZodiacSignEnum.libra,
  8: ZodiacSignEnum.scorpio,
  9: ZodiacSignEnum.sagittarius,
  10: ZodiacSignEnum.capricorn,
  11: ZodiacSignEnum.aquarius,
  12: ZodiacSignEnum.pisces,
}

export enum CustomAttributesEnum {
  ZODIAC_SIGN = "zodiacSign",
  NO_INDEX = "searchEngineDenied",
}

// for the initial release all the categories are hardcoded
// I guess this shit has to be removed
// since we are not doing categories filtering on FE
export enum CategoriesEnum {
  "Tarot & Kartenlegen" = "20000407",
  "Hellsehen & Wahrsagen" = "20000405",
  "Medium & Channeling" = "20000408",
  "Astrologie & Horoskope" = "20000244",
}

export enum LOCAL_STORAGE_KEYS {
  PRODUCT_FLOW_SETTINGS = "qco:product_flow_settings",
  PROMOTION_REMINDER_MODAL_DISPLAYED = "qco:promotion_reminder_modal_displayed",
  PIN_VERIFICATION_COUNT = "qco:pin_verification_count",
  PIN_VERIFICATION_RESEND_TIMESTAMP = "qco:pin_verification_resend_timestamp",
  /** aws tracking data. Fraud prevention */
  AWS_TRACKING_DATA = "qco:aws_f",
  RECURRING_PROCESSING_MODEL_3DS = "qco:recurring_processing_model",
  FAVORITE_EXPERTS_STATUSES = "qco:favorite_experts_statuses",
  /**
   * fingerprintjs uses this key to access visitor id.
   * Could be changed independently from us
   */
  FINGERPRINTJS_GET_RESULT = "qco:fp",
  RECENT_EXPERTS_DATA = "qco:recent_experts_data",
  HAS_SEEN_RECENT_ANIMATION = "qco:has_seen_recent_animation",
  /**
   * Sent from kcms iFrame before submitting registration form
   * Activated after successfull registration
   * Cleared after redeeming
   */
  CLIENT_TRACKING_CHECKOUT_ID = "qco:client_tracking_checkout_id",
  AUTH_DATA = "qco:auth_data",
  AUTH_SUCCESS_REDIRECT_URL = "qco:auth_success_redirect_url",
  AUTH_FAILED_REDIRECT_URL = "qco:auth_failed_redirect_url",
  IS_QPLUS_SUBSCRIBER = "qco:is_qplus_subscriber",
  GREETINGS_SEEN = "qco:greetings_seen",
  WIZARD_AUTHENTICATION = "qco:wizard_authentication",
  RATING_SUCCESS_SECTION_CUR_DATA = "qco:rating_success_section_cur_data",
  CUR_QMAIL_DATA = "qco:cur_qmail_data",
}

export enum GraphqlClientNameEnum {
  ADVIQO = "adviqo",
  CONTENTFUL = "contentful",
}

export enum TypeOfHoroscope {
  daily = 101,
  weekly = 102,
  monthly = 113,
  yearly = 114,
}

export enum ZodiacSign {
  aries = 1,
  taurus = 2,
  gemini = 3,
  cancer = 4,
  leo = 5,
  virgo = 6,
  libra = 7,
  scorpio = 8,
  sagittarius = 9,
  capricorn = 10,
  aquarius = 11,
  pisces = 12,
}

export enum DateShift {
  today = 0,
  tomorrow = 1,
  yesterday = -1,
  "day after tomorrow" = 2,
}

export enum PageSlug {
  PROFIL = "profil",
  KONTO = "konto",
  KONTO_AUFLADEN = "kontoaufladen",
  BERATER = "berater",
  GUTSCHEIN = "gutschein",
  QMAIL = "qmail",
  VMAIL = "vmail",
  LOGIN = "login",
  REGISTRATION = "registrierung",
  PASSWORD_RECOVERY = "password-recovery",
  RESET_PASSWORD = "reset-password",
  QUESTICO_DASHBOARD = "mein-questico",
  VIVERSUM_DASHBOARD = "mein-viversum",
  CHECKOUT = "checkout",
  CALL = "call",
  CHAT = "chat",
  MY_ADVISORS = "my-advisors",
  RECOMMENDATIONS = "recommendations",
}

export enum ProductFlowStep {
  chatting = "chatting",
  checkout = "checkout",
  payment = "payment",
  connecting = "connecting",
  calling = "calling",
  failed = "failed",
  /** @deprecated */
  success = "success",
  reserving = "reserving",
  reservationSuccess = "reservationSuccess",
  reservationFailed = "reservationFailed",
  feedback = "feedback",
  rating = "rating",
  cheers = "cheers",
}

export enum ClientTrackingEventName {
  USER_SINGED_IN = "User Signed In",
  USER_SINGED_OUT = "User Signed Out",
  USER_SINGED_IN_FAILED = "Sign In Failed",
  USER_SINGED_UP_FAILED = "User Signup Failed",
  PASSWORD_RECOVERY = "Password Recovery Requested",
  PASSWORD_RECOVERY_FAILED = "Password Recovery Failed",
  PASSWORD_UPDATED = "Password Updated",
  PASSWORD_UPDATE_FAILED = "Password Update Failed",
  PROFILE_UPDATE_FAILED = "Profile Update Failed",
  CALLBACK_REQUESTED = "Callback Requested",
  CALLBACK_REQUEST_FAILED = "Callback Request Failed",
  CALL_INITIATED = "Call Initiated",
  CALL_INITIATION_FAILED = "Call Initiation Failed",
  CHAT_INITIATED = "Chat Initiated",
  CHAT_INITIATION_FAILED = "Chat Initiation Failed",
  EXPERT_MESSAGED = "Expert Messaged",
  BANNER_CLICKED = "Banner Clicked",
  PRODUCT_SEARCH = "Products Searched",
  PRODUCT_LIST_VIEW = "Product List Viewed",
  PRODUCT_LIST_FILTERED = "Product List Filtered",
  PRODUCT_VIEWED = "Product Viewed",
  PRODUCT_CLICK = "Product Clicked",
  PRODUCT_FAVOURITE_ADD = "Product Favourite Added",
  PRODUCT_FAVOURITE_REMOVE = "Product Favourite Removed",
  PRODUCT_REVIEWED = "Product Reviewed",
  PRODUCT_ADD = "Product Added",
  CHECKOUT_START = "Checkout Started",
  CHECKOUT_COMPLETED = "Checkout Completed",
  COUPON_CHANGED = "Coupon Changed",
  WIZARD_STARTED = "Wizard Started",
  WIZARD_EXITED = "Wizard Exited",
  WIZARD_COMPLETED = "Wizard Completed",
  WIZARD_STEP_VIEWED = "Wizard Step Viewed",
  WIZARD_STEP_COMPLETED = "Wizard Step Completed",
  FEEDBACK_PROVIDED = "Feedback Provided",
  RATING_POPUP_VIEWED = "Rating Popup Viewed",
  BILLING_CHANGE_INITIATED = "Billing Change Initiated",
  BILLING_CHANGE_CANCELLED = "Billing Change Cancelled",
  BILLING_CHANGED = "Billing Changed",
  PAYMENT_CHANGE_INITIATED = "Payment Change Initiated",
  PAYMENT_CHANGE_CANCELLED = "Payment Change Cancelled",
  PAYMENT_CHANGED = "Payment Changed",
  PAYMENT_CHANGE_FAILED = "Payment Change Failed",
  PAYMENT_SUBMITTED = "Payment Submitted",
  PAYMENT_ADDED = "Payment Added",
  PAYMENT_SUBMIT_FAILED = "Payment Submit Failed",
  TOPUP_COMPLETED = "Topup Completed",
  TOPUP_SUBMITTED = "Topup Submitted",
  TOPUP_FAILED = "Topup Failed",
  PHONE_CHANGE_INITIATED = "Phone Change Initiated",
  PHONE_SUBMITTED = "Phone Submitted",
  PHONE_SAVED = "Phone Saved",
  PHONE_CHANGE_FAILED = "Phone Change Failed",
  PHONE_CHANGE_REQUIRED = "Phone Change Required",
  PIN_ACCEPTED = "Pin Accepted",
  PIN_REJECTED = "Pin Rejected",
  PIN_REQUESTED = "Pin Requested",
  PIN_SUBMITTED = "Pin Submitted",
  REVIEWS_VIEWED = "Reviews Viewed",
}

export const protectedRoutes = [
  `/${PageSlug.CHECKOUT}`,
  `/${PageSlug.CALL}`,
  `/${PageSlug.CHAT}`,
  `/${PageSlug.QUESTICO_DASHBOARD}`,
  `/${PageSlug.VIVERSUM_DASHBOARD}`,
  `/${PageSlug.MY_ADVISORS}`,
  `/${PageSlug.RECOMMENDATIONS}`,
] as const

export type TProtectedRoutes = typeof protectedRoutes

export const filterableProductTypes = ["ALL", "CALL", "CHAT"] as const

export type CountryCode = Country

export enum CallStatusType {
  CREATED = "created",
  IN_PROGRESS = "in_progress",
  COMPLETED = "ended",
  FAILED = "failed",
}

export enum ChatStatus {
  CREATED = "created",
  IN_PROGRESS = "in_progress",
  ENDED = "ended",
  FAILED = "failed",
}

export enum CustomHeader {
  AUTH = "Authorization",
  TENANT = "X-TENANT-ID",
  PATHNAME = "x-pathname",
  X_TRACKING = "X-Tracking",
  SKIP_CONSENT = "x-skip-consent",
}

export enum KnownPages {
  HOME = "Home",
  CALL = "Call Flow",
  CHAT = "Chat Flow",
  CHECKOUT = "Checkout",
  EXPERT = "Expert Profile",
  WIZARD = "Wizard",
  /**
   * pages created with cath-all page (src/pages/[...slug])
   */
  CONTENTFUL = "",
  LOGIN = "Login",
  REGISTER = "Registration",
  RESET_PASSWORD = "Reset Password",
  PASSWORD_RECOVERY = "Password Recovery",
  SEARCH = "Search",
  MY_ADVISORS = "My advisors",
  MY_ACCOUNT = "Account",
  PREFERENCES = "Preferences",
  RATINGS = "Ratings",
  FAVOURITES = "Favourites",
  TOP_UP_ACCOUNT = "Top Up Account",
  CONVERSATIONS = "Chat",
  TRANSACTIONS = "Account Movements",
  QMAIL = "Qmail",
  VOUCHERS = "Vouchers",
  NOT_FOUND = "Error 404",
  SERVER_ERROR = "Error 500",
}

//  ######## ##    ## ########  ########  ######
//     ##     ##  ##  ##     ## ##       ##    ##
//     ##      ####   ##     ## ##       ##
//     ##       ##    ########  ######    ######
//     ##       ##    ##        ##             ##
//     ##       ##    ##        ##       ##    ##
//     ##       ##    ##        ########  ######

export interface ContentfulNode {
  sys: { id: string }
  __typename: string
}

export type ILink = _ILink

export type IPageForMultilevelSlug = _IPageForMultilevelSlug

export interface IArticleOrPageLink {
  __typename: "Article" | "Page"
  sys: { id: string }
  slug: string
  parentSlug: IMultiLevelSlug | null
}

export type ISlugOrArticleOrPage =
  | IMultiLevelSlug
  | IPageForMultilevelSlug
  | ILinkedFromPageItem
  | IHeadlinePage
  | IArticleOrPageLink

export type ContentfulRichText = {
  json: Document
  links?: {
    entries: {
      hyperlink: IArticleOrPageLink[]
      /**
       * https://adviqo.atlassian.net/browse/FEM-126
       */
      block: Array<IFullDom | any>
      inline: Array<IFullDom | any>
    }
  }
}

export type MappedComponentsType =
  | "AuthModule"
  | "Paragraph"
  | "ExpertsListingWithFilters"
  | "ContentItemList"
  | "Article"
  | "HoroscopeContent"
  | "LinkBox"
  | "FullDom"
  | "ExpertAdsBanner"
  | "Wizard"

export type ILinkedFromPageItem = Omit<
  IPageForMultilevelSlug,
  "showNewLabel"
> & { rank: number | null }

export type IHeadlinePage = Omit<IPageForMultilevelSlug, "showNewLabel">

export interface IMultiLevelSlugNode extends IMultiLevelSlug {
  page: Omit<IPageForMultilevelSlug, "parentSlug"> | null
  linkedFrom: {
    pageCollection: {
      items: ILinkedFromPageItem[]
    }
  } | null
}

export type IListingNumber = string

export type TFilterProductType = typeof filterableProductTypes[number]

export type TRatingValue = 1 | 2 | 3 | 4 | 5

export type TPriceFilter = {
  productType: TFilterProductType
  predicate: "lt" | "lte" | "gt"
  value: number
}

export interface ListingsFetchResult {
  content: Array<IAdvisor>
  isLast?: boolean
}

export type TLanguage = {
  code: string
  name: string
}

export type TLanguages = Array<TLanguage>

/** @TODO this has to be upgraded to have another kind of possible id/values pairs */
export type IdValue = {
  id: CustomAttributesEnum | string
  value: string
  __typename?: string
}

export type ICustomAttributesArray = Array<IdValue>

export interface IPersonalNote {
  text: string
  textTimestamp: number | Date
}

export type MultimediaType = "PROFILE_PHOTO" | "PHOTO" | "AUDIO" | "VIDEO"

export interface MultimediaProfileItem {
  multimediaType?: MultimediaType
  url?: string
  __typename?: string
}

export interface IQualification {
  id: number
  title: string
  year: number
}

export interface ISeminar extends IQualification {
  description: string
}

export enum ClientTrackingOnlineStatus {
  FREE = "online",
  OFFLINE = "offline",
  BUSY = "busy",
}

export interface IExpertEntry {
  listingNo: IListingNumber
  displayName: string
  titleDescription?: string
  photo: string
  products: IAdvisorProduct[]
  status: IAdvisorStatus
}

export interface IExpert extends IAdvisor {
  contactDateTime?: number
  listingName?: string
}

export interface IAdvisorBase {
  id: number
  listing_number: IListingNumber
  name: string
  avatar: string
}

export interface IAdvisor extends IAdvisorBase {
  status: IAdvisorStatus
  gender: IAdvisorGender
  advisor_code: string
  alias: string
  description: string
  is_favorite: boolean
  readings_count: number
  search_engine_denied: boolean
  rating: IAdvisorRating
  labels: IAdvisorLabels
  products: IAdvisorProduct[]
  promotion: IPromotion | null
  /**
   * not available yet via Advisor API
   */
  languages?: TLanguages
  /**
   * not available yet via Advisor API
   */
  sign: IExpertZodiacSign
  is_prs_enabled?: boolean
}

export interface IReservationsExpertEntry {
  advisor: IAdvisor
  expire_at: string
  id: number
  position: number
}

export interface FetchQueuedConnectionsResult {
  queuedConnections: Array<IReservationsExpertEntry>
}

export type TExpertEntry = IExpert | IReservationsExpertEntry

/* export interface IExpertListing {
  advisor: IAdvisor
  listingDetail: IListingDetail
  languages: string[]
} */

export interface IPrice {
  price: number
  strikethroughPrice: number | null
  __typename?: string
}

export interface IPackage {
  /** Parameter in a subsequent call request */
  connectionPackageNo: string
  /** Minutes in package */
  duration: number | null
  /** Additional free minutes in this package */
  freeMinute: number | null
  price: IPrice
}

export type TPackagePromoType =
  | "bestseller"
  | "qplus"
  | "none"
  | "recommendation"

export interface IPackageExtentension {
  preselected?: boolean
  packagePromoType?: TPackagePromoType | undefined
  order?: number
}

export type ICheckoutPackage = Replace<
  IProductPackage & Required<IPackageExtentension>,
  "price_sale",
  {
    price_sale: IProductPackage["price_sale"] | null
  }
>

// list of voucher types
export enum PromotionVoucherType {
  INTRO_PROMOTION_DAILY = "intro_promotion_daily",
  INTRO_PROMOTION_TERMINATED = "intro_promotion_terminated",
  PROMOTIONAL_CREDIT_DAILY = "promotional_credit_daily",
  PROMOTIONAL_CREDIT_TERMINATED = "promotional_credit_terminated",
  FIXED_RATE_DAILY = "fixed_rate_minutes_daily",
  FIXED_RATE_TERMINATED = "fixed_rate_minutes_terminated",
}

export type IAdvisorStatus = "online" | "offline" | "busy"

export type IAdvisorProductType = "chat" | "call"

export type IAdvisorGender = "male" | "female" | "unknown"

export enum IAdvisorListingFieldState {
  ON = "on",
  OFF = "off",
}

export enum PromotionType {
  FREE_MINUTES = "free-minutes",
  WELCOME_FREE_MINUTES = "welcome-free-minutes",
  FREE_CREDIT = "free-credit",
  DISCOUNT = "discount",
  OTHER = "other",
}

export enum PromotionStatus {
  CREATED = "created",
  APPLIED = "applied",
  USED = "used",
  EXPIRED = "expired",
  DELETED = "deleted",
}

interface IBasePromotion {
  id: number
  type: PromotionType
  status: PromotionStatus
  title: string
  image: string
  free_minutes: number
  free_credit: number
  amount_max: number
  currency: string
  discount_percent: number
  expire_at: string | null
}

export interface IPromotionAdvisor extends IBasePromotion {
  advisor: IAdvisor | null
}

export interface IPromotion extends IBasePromotion {
  advisor_id: string | null
}

export interface IProductPackage {
  id: number
  title: string
  paid_minutes: number
  free_minutes: number
  bestseller: boolean
  default: boolean
  price: number
  price_sale: number | null
}

export interface IAdvisorProduct {
  type: IAdvisorProductType
  is_available: boolean
  currency: string
  price: number
  price_sale: number | null
  discount_percent: number
  packages: IProductPackage[]
}

export interface IAdvisorRating {
  average: number
  average_rounded: number
  count: number
}

export interface IAdvisorLabels {
  is_new: boolean
  is_astro_tv: boolean
  is_premium: boolean
}

// taken from master, subject to fix
export interface IProductWithPackages extends IAdvisorProduct {
  packageList: Array<IPackage>
}

export interface IReview {
  id: number
  author: string
  rating: string
  text?: string
  created_at: string
  title?: string
}

export interface IAdvisorProfile {
  advisor: IAdvisor
  details: {
    description_teaser: string
    description_experience: string
    description_about: string
    interview: string
    availability_details_last_change: string | null
    availability_details: string
    video: IExpertVideo | null
    audio_intro: string | null
    zodiac_sign: IExpertZodiacSign | null
    qualifications: IQualification[]
    seminars: ISeminar[]
  }
  // todo move this to separate endpoint
  reviews: {
    count: number
    totals: { count: number; value: TRatingValue }[]
    list: IReview[]
  }
  categories: ICategory[]
  languages: TLanguages
}

export interface IExpertZodiacSign {
  id: number
  alias: ZodiacSignAliasesEnum
  name: string
  date_range: string
  from_month: number
  from_day: number
  to_month: number
  to_day: number
}

interface IExpertVideo {
  url: string
  preview: string
}
export interface ICategory {
  advisors_count: number
  name: string
  icon: string
  alias: string
  id: number
  type: "method" | "topic"
}

// component mapping types

export type Mapping = {
  [K in MappedComponentsType]: React.ComponentType<any>
}

/**
 * @description user service will send empty strings for fields with no value
 */
export interface IUser {
  email: string
  phoneNumber: string | null
  nickName: string
  phoneVerified: boolean
  birthDate: string | null
  /**
   * we do nothing with this
   * @see https://adviqo.atlassian.net/browse/FEM-286
   */
  emailVerificationRequired: boolean
}

export type TGraphQLClient =
  | ApolloClient<NormalizedCacheObject>
  | UseTypeOf<Required<ApolloContextValue>, "client">

export interface LinkProps {
  id: string
  title: string
  url: string
  showNewLabel?: boolean | null
  subListTitle?: string
  links?: LinkProps[]
  onClick?: (e: React.MouseEvent) => void
  dataTestId?: string
  style?: {
    bold?: boolean
  }
}

export type TComponentsReferences =
  | "offerbar"
  | "navbar"
  | "banner"
  | "filtersPortal"
  | "promotionsSnackbar"

export interface IHeaderRenderSupport {
  headerWithHeroImage: boolean
  headerWithNavBar: boolean
  headerWithOfferBar: boolean
  headerWithBanner?: boolean
  headerWithLogo?: boolean
}

export type TSocialNetwork =
  | "facebook"
  | "instagram"
  | "pinterest"
  | "youtube"
  | "tiktok"

export interface IGroupedItems {
  [key: string]: {
    navItems: Array<IMultiLevelSlugNode>
    headlinePage: IHeadlinePage | null
  }
}

export type PageContext = {
  id: string
}

export interface ExtendedGraphqlContext extends Context {
  clientName?: GraphqlClientNameEnum
  cachedToken?: string
}

export interface IAdviqoLinkProps extends Omit<MaterialUILinkProps, "variant"> {
  to: string | UrlObject
  as?: string
  className?: string
  onClick?: React.MouseEventHandler
  target?: "_blank" | "_self" | "_parent" | "_top"
}

export interface ProductFlowParams {
  step: ProductFlowStep
  isFree: boolean
  listingNo: IListingNumber
  productType: IAdvisorProductType
  photo?: string
  displayName?: string
  phoneNumber?: string
  errorCode?: ErrorCode
  price?: string
  priceSale?: string
  initSrc?: string
  waitingNumber?: string
  reviewRequestId?: number
  stepToSkip?: string
  productId?: string
  provider?: IChatProvider
  [k: string]: any
}

export type ICheckoutFlowParams = Pick<
  ProductFlowParams,
  "listingNo" | "productType" | "isFree"
>

export type TAuthenticationAction = "login" | "register"

export type TTrackingAction =
  | TAuthenticationAction
  | "call"
  | "chat"
  | "interactPopup"
  | "insert"
  | "logout"

export interface IResultData {
  error: string | undefined
  data?: any
}

/**
 * @see https://adviqo.atlassian.net/wiki/spaces/EN/pages/2228565/Error+Handling
 */
export enum CommonErrors {
  "UNAUTHORIZED" = 401,
  "USER_NOT_AUTHENTICATED" = 1001,
  "SERVER_ERROR" = 500,
}

export enum CustomFrontendError {
  "FREE_PRODUCT_IS_NOT_AVAILABLE_ANYMORE" = 0,
  "PASSWORDS_DONT_MATCH" = 1,
  "WRONG_USERNAME" = 2,
  "EMPTY_FIELD" = 3,
  /**
   * useful to identify that product flow cannot be continued
   * due to various reasons (lack of required information).
   * This supposed to lead to "something went wrong" scenario
   * @example
   * After authentication, chosen product isn't provided by expert anymore
   */
  "PRODUCT_FLOW_FATAL_ERROR" = 4,
}

export enum EndChatConditions {
  INITIAL_TIMEOUT = 201,
  MEMBER_INITIAL_TIMEOUT = 202,
  EXPERT_INITIAL_TIMEOUT = 203,
  MEMBER_LOGOUT_BEFORE_CHAT = 204,
  EXPERT_LOGOUT_WITHOUT_MSG = 205,
  TIMEOUT = 210,
  MEMBER_TIMEOUT = 211,
  EXPERT_TIMEOUT = 212,
  MEMBER_CREDITCARD_INVALID = 219,
  MEMBER_LOGOUT = 221,
  EXPERT_LOGOUT = 222,
  MAX_DURATION_REACHED = 223,
  SYSTEM_LOGOUT = 224,
  MEMBER_CREDITCARD_INSUFFICIENT_FUNDS = 225,
  MEMBER_BLOCKED = 226,
}

export type ErrorCode = CommonErrors | EndChatConditions | CustomFrontendError

export interface IReviewsRequests {
  id: number
  advisor_id: number
  customer_id: number
  session_id: number
  session_type: IAdvisorProductType
  status: "skipped" | "postponed" | "published" | "expired" | "canceled"
  created_at: string
  expired_at: string
}

export type TReviewsRequestsStatusForPayload = Extract<
  IReviewsRequests["status"],
  "postponed" | "skipped"
>

export type IChatProvider = "Twilio" | "ACS"

export interface IChatDetails {
  id: number
  acs_id: string
  session_id: number
  status: ChatStatus
  conversation_id: string
  /**
   * @deprecated
   * id of Twilio conversation
   */
  conversation_sid: string
  last_message: string
  auth: string
  duration: number
  widget_url: string
  advisor: IAdvisor
  created_at: string
  ended_at: string
  started_at: string | null
  review_request: IReviewsRequests | null
  provider: IChatProvider
}

export interface ICallDetails {
  id: number
  server_reference: string
  duration: number
  status: CallStatusType
  ended_at: string
  advisor: IAdvisor
  review_request: IReviewsRequests | null
}

export type TCurrency = "EUR"

export interface IJWTProfile extends JwtPayload {
  user_name: string
  profile_id: "user" | "advisor"
  user_id: number
}

export interface IInitTraits {
  brand: "Questico" | "Viversum"
  current_locale: string
  google_analytics_ga: string | undefined
  current_campaign: ICurrentCampaign
  current_tracking_id: ICurrentTrackingID
}

type TMarketingQueryParameter = string | string[] | null

interface ICurrentCampaign {
  referer: TMarketingQueryParameter
  pid: TMarketingQueryParameter
}

export interface ICurrentTrackingID {
  gclid: TMarketingQueryParameter
  fbclid: TMarketingQueryParameter
  transaction_id: TMarketingQueryParameter
}

export interface ITraits
  extends Partial<IInitTraits>,
    Partial<ICurrentCampaign>,
    Partial<ICurrentTrackingID> {
  user_status?: TUserStatus
  user_type?: "user" | "expert"
  consent?: Record<string, boolean>
  fingerprint?: string
}

export enum AdviiCustomEvent {
  TOKEN_EXPIRED = "tokenExpired",
  TOKEN_REFRESH_SUCCESS = "tokenRefreshSuccess",
  TOKEN_REFRESH_FAILURE = "tokenRefreshFailure",
}

export type IProductFlow = "call" | "chat"

export interface IFlattenURLQuery {
  [key: string]: string | undefined
}

export type IAdviiPageContext<T = Record<string, unknown>> = NextPageContext & {
  apolloClient: TGraphQLClient
} & T

export type IMaybeEmptyString = "" | string

export type ICookieGetter = () => string
export type ICookieSetter = (value: string) => void

export enum Cookie {
  TOKEN = "TOKEN",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  ANONYMOUS_ID = "qco:anonymous_id",
}

export enum FiltersModuleFieldId {
  TITLE = "title",
  EXPERT_LIST_HEADLINE = "expertListHeadline",
  GENDER = "gender",
  LANGUAGE = "language",
  PRODUCT_TYPE = "productType",
  METHOD = "method",
  TOPIC = "topic",
  PRICE = "price",
  SORTING = "sorting",
  WITH_PROMOTION = "withPromotion",
  SIZE = "size",
  INCLUDE_LISTING_NOS = "includeListingNos",
  TAG = "tag",
  WITH_PAGINATION = "withPagination",
  FREE_ONLY = "freeOnly",
  DISPLAY = "display",
}

export enum FiltersModuleDisplay {
  FILTERS = "filters",
  LIST = "list",
}

export enum FiltersModuleMode {
  FULL = "full",
  LIMITED = "limited",
}

export interface IFilterOption {
  id: string | number
  name?: string
  enabled: boolean
  children?: IFilterOption[] | string[]
}

export interface IExpertsFilter {
  options: IFilterOption[]
  defaultOption: IFilterOption["id"] | IFilterOption["id"][]
  initOption?: IFilterOption["id"] | IFilterOption["id"][]
  defaultOptionTitle?: string
  expanded?: boolean
  title: string
  enabled: boolean
  id: FiltersModuleFieldId
}

export interface IExpertsFilterHub {
  filters: IExpertsFilter[]
  sorting?: IExpertsFilter
}

export enum IWizardStepTypes {
  MOOD = "mood",
  HOROSCOPE = "horoscope",
  PRODUCT_TYPE = "productType",
  METHOD = "method",
  TOPIC = "topic",
}

export interface IRatingPolicyJSON {
  enabled: boolean
  content: IMaybeEmptyString
  title: {
    mobile: IMaybeEmptyString
    desktop: IMaybeEmptyString
  }
  media: {
    desktop: IMaybeEmptyString
    mobile: IMaybeEmptyString
  }
  link: {
    title: IMaybeEmptyString
    url: IMaybeEmptyString
  }
}

export interface IExpertsSuggestionsJSON {
  orderBy: string
  refetchIntervalMs: number
}

export interface IExpertSettings {
  ratingPolicy: IRatingPolicyJSON
  expertsSuggestions: IExpertsSuggestionsJSON
}

export type IConfigHeroImageCollection = {
  __typename?: "ConfigHeroImageCollection"
  items: Array<{
    sys: { id: string }
  }>
}

export enum VerticalAlign {
  TOP = "top",
  CENTER = "center",
  BOTTOM = "bottom",
}

export enum TextAlign {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  JUSTIFY = "justify",
}

export interface IOfferBarIcon {
  url: string
  width: number
  height: number
}

export enum CustomerGenderEnum {
  MALE = "male",
  FEMALE = "female",
  UNKNOWN = "unknown",
}

export interface ICustomerBalance {
  amount: number
  currency: string
}

export interface ICountry {
  id: number
  name: string
  code: string
  phoneCode: string
}

export interface ICustomerPhone {
  id: number
  code: string
  number: string
  is_main: boolean
  country: ICountry
}

export interface ICustomerZodiacSign {
  id: number
  alias: ZodiacSignEnum
  name: string
  date_range: string
  from_month: number
  from_day: number
  to_month: number
  to_day: number
}

export interface ICustomerPersonal {
  first_name: string
  last_name: string
  phone: string
  gender: CustomerGenderEnum
  zodiac_sign: ICustomerZodiacSign | null
  birthday_year: number | null
  birthday_month: number | null
  birthday_day: number | null
}

export interface ICustomerProfile {
  id: number
  email: string
  login: string
  name: string
  avatar: string
  balance: ICustomerBalance
  personal: ICustomerPersonal
  phones: ICustomerPhone[]
  freshchat_restore_id: string
  chat_provider: IChatProvider
}

export type TMessageType = "error" | "warning" | "success" | "info" | "banner"

export interface IFeedbackMessage {
  variant: TMessageType
  message: string | JSX.Element
}

export interface IOnPhoneNumberUpdateOptions {
  reason:
    | "saveClicked"
    | "changeInitiated"
    | "updateSuccess"
    | "updateError"
    | "pinVerification"
    | "systemSwitchedMode"
  message?:
    | "phoneAdded"
    | "phoneChanged"
    | "phoneNotChanged"
    | "changeFailed"
    | "changeRequired"
    | "pinRequested"
    | "pinSubmitted"
    | "pinRejected"
    | "pinAccepted"
    | "validationFailed"
    | string
    | undefined
  error?: string
}

export interface IPreference {
  name: string
  description: string
  title: string
  enabled: boolean
}

export interface IPreferenceGroupData {
  name: string
  title: string
  items: IPreference[]
}

export interface IPreferencesData {
  groups: IPreferenceGroupData[]
  error: string | undefined
  loading: boolean | undefined
}

export interface ICallQueueDetails {
  advisor: IAdvisor
  id: number
  position: number
}

export type TranslateFn = (str: string) => string

/**
 * "sidebar" – below navigation menu. Relates to REVIVE_LEFT_DESKTOP_ZONE_ID
 * "main" – in main area. Relates to REVIVE_CENTER_DESKTOP_ZONE_ID and REVIVE_CENTER_MOBILE_ZONE_ID
 */
export type IReviveAdsPosition = "main" | "sidebar"

export interface IReviveConfig {
  reviveId: string
  zoneId: string
}

export type IGetFPData = VisitorQueryContext<true>["getData"]

export type ILogLevel = "log" | "info" | "debug" | "warn" | "error"
