import { NextComponentType } from "next"
import * as React from "react"

import { getRuntimeConfig } from "../../utils/runtimeConfig"
import { Environment } from "../../types"
import { isWEBPSupported, isMobile } from "./featuresDetect"
import {
  TFeatures,
  IWithBrowserFeaturesDetectorProps,
  IWithBrowserFeaturesDetectorContext,
  IWithBrowserFeaturesProps,
} from "./types"
import BrowserFeaturesProvider from "./BrowserFeaturesProvider"

export function withBrowserFeaturesDetector<
  Props extends IWithBrowserFeaturesDetectorProps,
  InitialProps
>(
  ComposedComponent: NextComponentType<
    IWithBrowserFeaturesDetectorContext,
    InitialProps,
    Props
  >
): NextComponentType<IWithBrowserFeaturesDetectorContext, InitialProps, Props> {
  const name: string = ComposedComponent.displayName || ComposedComponent.name

  let features: TFeatures

  const WithBrowserFeaturesDetectorWrapper: React.FC<Props> = (props) => {
    if (!features && typeof window !== "undefined") {
      const { userAgent } = window.navigator

      features = {
        webp: isWEBPSupported(userAgent),
        isMobile: isMobile(userAgent),
        domainPath: window.location.origin,
      }
    }

    return (
      <BrowserFeaturesProvider features={features}>
        <ComposedComponent {...props} />
      </BrowserFeaturesProvider>
    )
  }

  WithBrowserFeaturesDetectorWrapper.displayName = `withBrowserFeaturesDetector(${name})`
  ;(WithBrowserFeaturesDetectorWrapper as NextComponentType).getInitialProps = async (
    context: IWithBrowserFeaturesDetectorContext
  ): Promise<IWithBrowserFeaturesProps> => {
    let uaString: string | undefined
    let pageProps = {} as InitialProps

    let domainPath = ""

    const { ENVIRONMENT, BASE_URL } = getRuntimeConfig()

    if (typeof context.req !== "undefined") {
      const { headers } = context.req

      uaString = headers["user-agent"]
      domainPath =
        ENVIRONMENT === Environment.LOCAL
          ? `http://${headers.host}`
          : `https://${headers.host}` || BASE_URL.replace(/\/+$/, "")
    } else if (typeof window !== "undefined") {
      uaString = window.navigator.userAgent
      domainPath = window.location.origin
    }

    features = {
      webp: isWEBPSupported(uaString),
      isMobile: isMobile(uaString),
      domainPath,
    }

    // eslint-disable-next-line no-param-reassign
    context.features = { ...features } as TFeatures

    if (ComposedComponent.getInitialProps) {
      pageProps = await ComposedComponent.getInitialProps!(context)
    }

    if (context.features) {
      // eslint-disable-next-line no-param-reassign
      delete context.features
    }

    return {
      ...pageProps,
      features,
    }
  }

  return WithBrowserFeaturesDetectorWrapper
}
